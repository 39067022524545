<template>
    <div>
        <p class="control has-icons-right">
            <input class="h-9 border rounded-md pl-3 w-full" v-bind="$attrs" v-model="valueLocal" v-on="inputListeners" placeholder="0,00" />
            <span class="icon is-right">{{ rightText }} </span>
            <span class="icon is-small is-right">
                <i :class="rightIcon" />
            </span>
        </p>
        <p v-if="error" class="help is-danger">{{ error }}</p>
    </div>
</template>
<script>
export default {
    props: ["error", "value", "rightText", "rightIcon", "allowZeroStart"],
    methods: {
        getPosition(string, subString, index) {
            return string.split(subString, index).join(subString).length;
        },
        replaceChar(origString, replaceChar, index) {
            let firstPart = origString.substr(0, index);
            let lastPart = origString.substr(index + 1);
            let newString = firstPart + replaceChar + lastPart;
            return newString;
        },
    },
    computed: {
        valueLocal: {
            get() {
                let amount = "0";
                if (this.value != null) {
                    amount = this.value.toString();
                }

                // eslint-disable-next-line no-useless-escape
                amount = amount.replace(/[^0-9\.,-]/g, "");

                var index = this.getPosition(amount, "-", 2);
                amount = this.replaceChar(amount, "", index);

                var minusIndex = amount.indexOf("-");
                if (minusIndex != 0 && minusIndex != -1) {
                    amount = this.replaceChar(amount, "", minusIndex);
                }
                amount = amount.split(".").join(",");

                var commaIndex = this.getPosition(amount, ",", 2);
                amount = this.replaceChar(amount, "", commaIndex);

                var commaZeroIndex = amount.indexOf(",");
                if (commaZeroIndex == 0) {
                    amount = this.replaceChar(amount, "", commaZeroIndex);
                }
                return amount;
            },
            set(newValue) {
                var index = this.getPosition(newValue, "-", 2);
                newValue = this.replaceChar(newValue, "", index);

                var minusIndex = newValue.indexOf("-");
                if (minusIndex != 0 && minusIndex != -1) {
                    newValue = this.replaceChar(newValue, "", minusIndex);
                }
                // eslint-disable-next-line no-useless-escape
                this.$emit("input", newValue.replace(/[^0-9\.,-]/g, ""));
            },
        },
        inputListeners: function() {
            var vm = this;
            // `Object.assign` merges objects together to form a new object
            return Object.assign(
                {},
                // We add all the listeners from the parent
                this.$listeners,
                // Then we can add custom listeners or override the
                // behavior of some listeners.
                {
                    // This ensures that the component works with v-model
                    input: function(event) {
                        vm.$emit("input", event.target.value.replace(",", "."));
                    },
                },
            );
        },
    },
};
</script>
